import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { ChatService } from './chat.service';
import { ScrollDirective } from './scroll.directive';
import { CurrentUser } from '@auth/current-user';
import { WebSocketService } from '@core/services/web-socket.service';
import { randomString } from '@core/utils/random-string';
import { Echo } from 'laravel-echo-ionic';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Directive } from '@angular/core';
import { Settings } from '@core/config/settings.service';
import { Injectable, Injector } from '@angular/core';
import { Bootstrapper } from '@core/bootstrapper.service';
import { LessonsService } from '@student/lessons/lessons.service'; 
import { TutorLessonsService } from '@tutor/tutorlessons/tutorlessons.service'; 
import { FindTutor } from '@student/findtutor/findtutor.service';
import { FormBuilder, Validators } from '@angular/forms';
declare var $;
import { BehaviorSubject } from 'rxjs';
// import { SocketService } from '@services/socket.service';
import { PusherService } from 'app/pusher.service';
import { hexColorValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})

export class ChatComponent implements OnInit {
@ViewChild('closeViewDetailsModal') closeViewDetailsModal: ElementRef;
@ViewChild('scrollMe') private myScrollContainer: ElementRef;
@ViewChild('closeFileUploader') closeFileUploader: ElementRef;

  echo: any = null;
  $scope: any;
  public conversations;
  public conversationLists;
  public adminDetails;
  protected settings: Settings;
  public messages;
  public showOriginal:boolean = false;
  public statuses;
  public myId = this.currentuser.userId();
  public newMessage;
  public archeiveStatus:boolean = false;
  public selectedUserName;
  public archeiveChats;
  public isDataLoaded:boolean = false;
  public converstaionsLoader:boolean = false;
  public imageLoader$ = new BehaviorSubject(false);
  public chatLoader:boolean = false;
  public selectedUserID:number;
  public userLists;
  public authuserid;
  public host_socket;
  public searchmessages;
  public sentmsg = 'no';
  public showconversation = 'no';
  public readcount;
  public unread_count;
  public loading;
  public readcountstatus = 'no';
  public messagesconversations;
  public searching = 'no';
  public useravatar;
  public currentId = 0;
  public emptyspace = 'no';
  public responsereadcount = 1;
  public heightofscroll;
  public allfiles = [];
  public newfiles = [];
  public slicedelement22 = [];
  public slicedelement = [];
  public viewDetails;
  public scheduleCount;
  public previous_created_at:'';
  public selectedName;
  public join_room:string;
  public currentTab = 'activeTab';
  public reportForm = this.fb.group({
        description: ['', Validators.required],
  });
  get description(){ return this.reportForm.get('description');}
  title = 'dropzone';
  files: File[] = [];
  messageArray:Array<{user:String,message:String}> = [];
  formData = new FormData();
  constructor(public chat: ChatService,
              public scrollDirective: ScrollDirective,
              public currentuser: CurrentUser,
              private router: Router,
              private toastr: ToastrService,
              protected injector: Injector,
              private bootstrapper: Bootstrapper,
              private lessonService: LessonsService,
              private tutorLessonService: TutorLessonsService,
              public findtutor:FindTutor,
              // private _socketService:SocketService,
              private pusherService: PusherService,
              private fb: FormBuilder) {
       this.chat.sharedSelectedUserChat.subscribe(user=>{
       // console.log(user,'user');
      if(user!=null){
        this.selectUser(user['id'] , user['username'], user);
       }
      });
       this.settings = this.injector.get(Settings);
  //      this._socketService.newUserJoined()
  // .subscribe(data=> this.messageArray.push(data));

  // this._socketService.userLeftRoom()
  // .subscribe(data=>this.messageArray.push(data));

  // this._socketService.newMessageReceived()
  // .subscribe((data)=>{
  //   this.messageArray.push(data);
    
  //   this.getActiveChat();
  //   // this.getActiveConversations();
  //   this.getArchiveConversations();
  // });
    // this.pusherService.channel.bind("normal-chat",data=>{
    //   console.log("Pusher is working");
    // });
  }

  ngOnInit(): void {
    this.getActiveChat();
    this.chat.sharedSelectedUserChat.subscribe(user=>{
      //console.log(user,'user');
    if(user!=null){
      this.selectUser(user['id'] , user['username'], user);
     }
    });

    //pusher.logToConsole = true;

    // var pusher = new pusher('e50ffd2a92e54cd2fa08', {
    //   cluster: 'ap2'
    // });

    // var channel = pusher.subscribe('my-channel');
    // channel.bind('my-event', function(data) {
    //   alert(JSON.stringify(data));
    // });
//console.log(this.currentuser.isRole,this.myId,this.selectedUserID,'this.myId,this.selectedUserID')
    
        
  
  }

  public  createChannelPusher(selectedUserId){

    // channel name convention is being followed here as reach_chat_StudentId_TutorId
    // add student Id first and Admin/Tutor Id next
      this.chat.chatInfo().subscribe(response=>{
      this.conversations = JSON.parse(atob(response['chatInfo']));
      if(this.currentuser.isStudent()){
        this.pusherService.createChannel(this.myId,selectedUserId);
      }
      else if(selectedUserId == this.conversations.admin.id ){
        this.pusherService.createChannel(this.myId , selectedUserId);
      }
      else{
        this.pusherService.createChannel(selectedUserId,this.myId);
      }
   
    
    this.pusherService.channel.bind('tutor.message', data => {

     // console.log(data) ;
     // console.log('data.conversation.from_id') ;
      
      if(this.myId!=data.from_id && this.selectedUserID==data.from_id){ 
          let time = new Date().getTime();

          if(this.previous_created_at == data.created_at){
            return
          }else{
            this.previous_created_at = data.created_at
          }
         // console.log(time);
          let messagePush = Object();
          Object.assign(messagePush,  {to_id:""+data.to_id});
          Object.assign(messagePush,  {from_id:data.from_id});
          Object.assign(messagePush,  {message:data.message});
          Object.assign(messagePush,  {created_at:time});
          Object.assign(messagePush,  {time:time});
          Object.assign(messagePush,  {senderName:data.sender.username});
          Object.assign(messagePush,  {randomMsgId : data.id});
          if(data.file_name == null){
            Object.assign(messagePush,  {message_type :0});
          }else{
            Object.assign(messagePush,  {message_type :1});
          }
          Object.assign(messagePush,  {sendAsNotification: 'No'});
          this.messages['conversations'].push(messagePush);
      }
    });
  });
  }

  /*Get Active Users List*/
  public getActiveChat(){
      this.chat.chatInfo().subscribe(response=>{
       this.conversations = JSON.parse(atob(response['chatInfo']));

      // console.log('this.conversations',this.conversations );
       this.converstaionsLoader = true;
       this.chatLoader = false;
       this.userLists = this.conversations['conversationLists'];
       if(this.userLists.length>0){
        let firstUser = this.userLists['0'];
         this.selectUser(firstUser.user_id , firstUser.displayName , firstUser.user);
        // console.log(firstUser.user_id,'firstUser.user_id')
        $('#'+firstUser.user_id).addClass('activeUser chat__person-box--active');
       }
    });
  }

  /**Get the latest message */
  public getLatestMessage()
  {
    
  }

public initializeItems(){
  (this.currentTab == 'activeTab') ? this.conversations['conversationLists'] = this.userLists : this.archeiveChats = this.userLists;
}

filterUserList(searchTerm: string) {
 this.initializeItems();
  if (!searchTerm) {
    return;
  }

  let Users = (this.currentTab == 'activeTab') ? this.conversations['conversationLists'] : this.archeiveChats;

  Users = Users.filter(currentGoal => {
    if (currentGoal.displayName && searchTerm) {
      if (currentGoal.displayName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        return true;
      }
      return false;
    }
  });

  (this.currentTab == 'activeTab') ? this.conversations['conversationLists']  = Users : this.archeiveChats = Users;

}


searchconversations() {
  this.searching = 'yes';
} 
filtermessages(searchTerm: string, id) {
 this.conversationsuserid(id);
  if (!searchTerm) {
    this.chat.seletedUserConversation(id).subscribe(response=>{
      response = JSON.parse(atob(response['data']));
      this.messages = response['messages'];
    })
  }
  if ( this.messages['conversations'] != '' ) {
    if ( this.messagesconversations ) {
      if ( this.messagesconversations['conversations'] ) {
        this.messages['conversations'] = this.messagesconversations['conversations'];
      }
    }

    this.messages['conversations'] = this.messages['conversations'].filter(currentGoal => {
    if (currentGoal.message && searchTerm && currentGoal.message_type == 0) {
      if (currentGoal.message.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        return true;
      }
      return false;
    }
  });
  } else {
     this.chat.seletedUserConversation(id).subscribe(response=>{
      response = JSON.parse(atob(response['data']));
      this.messagesconversations = response['messages'];
      this.messages['conversations'] = this.messagesconversations['conversations'];
      this.filtermessages( searchTerm, id );
    })
  }
}

public conversationsuserid(id){
    this.selectedUserID = id;
  }

public keyPressHandler(e, id) {
  if ( (e.keyCode === 13) || (27 === e.which) || (13 === e.which) ) {
     this.sendMessage(id)
     e.preventDefault();
        $("[id='sendMessage']").each(function(){
            $(this).val("");
        });
  }
}

  // This Method is used to get the messages of the selected
  public userid(id){
    this.selectedUserID = id;
    this.chat.seletedUserConversation(id).subscribe(response=>{
      response = JSON.parse(atob(response['data']));
      this.messages = response['messages'];
      this.scheduleCount = response['schedulesCount'];
      this.showconversation = 'yes';
      this.scrollToBottom(id);
    });
  }

  public loadMore() {
    this.loading = 'up';
  }

  public archievedstatus(id){
    this.selectedUserID = id;
    // console.log(this.selectedUserID)
    this.chat.archievestatuses(id).subscribe(response=>{
      response = response['archive-statuses'];
      this.statuses = response;
    })
  }

  public message(messageValue){
  this.newMessage = messageValue;
  }

  // This Method is used to view the detalils of tyhe request
public viewRequestSource(notification){
    this.closeViewDetailsModal.nativeElement.click();
    this.router.navigate([notification.redirect_url]);
     if(notification.is_read == 0){
        this.tutorLessonService.readNotification(notification.id).subscribe(response=>{
      });
      }
}

  // This method is uded to send the message
  public sendMessage(id){
    
    if($("#sendMessage").val()){
    let time = new Date().getTime();
    let messagePush = Object();
    Object.assign(messagePush,  {to_id:""+id});
    Object.assign(messagePush,  {from_id:this.myId});
    Object.assign(messagePush,  {message:this.newMessage});
    Object.assign(messagePush,  {created_at:time});
    Object.assign(messagePush,  {time:time});
    Object.assign(messagePush,  {senderName:this.currentuser.userName()});
    Object.assign(messagePush,  {randomMsgId : randomString(13)});
    Object.assign(messagePush,  {message_type :0});
    Object.assign(messagePush,  {sendAsNotification: 'Yes'});

    $("#sendMessageBtn").hide();

     this.messages['conversations'].push(messagePush);
     this.chat.sendMessage(messagePush).subscribe(response=>{
      $("#sendMessage").val('');
      $("#sendMessageBtn").show();
      
    })
     this.scrollToBottom(id);
   }
  }

  // This Method is used to archeive the user
  public archieve(userId){
    this.chat.archiveUser(userId).subscribe(response =>{
      if(response['status'] == 'success'){
        this.conversations = JSON.parse(atob(response['chatInfo']));
        this.toastr.success('Chat Archieved SucessFully');
        this.showconversation = 'no';
      }
    })
  }

  // This Method is used to unarcheive the user
  public unArchieve(userId){
    this.chat.unArchiveUser(userId).subscribe(response =>{
      if(response['status'] == 'success'){
        this.conversations = JSON.parse(atob(response['chatInfo']));
        this.toastr.success('Chat Un Archieved SucessFully');
        this.getArchiveConversations();
        this.showconversation = 'no';
      }
    })
  }


  public archiveOptions(status){
    if(status == true){
    this.archeiveStatus = true;
    }else{
    this.archeiveStatus = false;
    }
  }


    // This Method will select the user to chat
  public selectUser(userId , name = '', userlist){
    //calling pusher to create a channel
    this.createChannelPusher(userId);
    //console.log(userId,'userId')
    this.viewDetails = '';
    this.selectedName = name;
    this.selectedUserName = userlist.username;
    if ( userlist.avatar ) {
      this.useravatar = userlist.avatar;
    } else {
      this.useravatar = userlist.avatar_url;
    }
    this.unread_count = 0;
    this.getreadcount(userId, this.unread_count);

    $("div.activeUser").removeClass("activeUser chat__person-box--active");
    $('#'+userId).addClass('activeUser chat__person-box--active');
    this.userid(userId);
    this.archievedstatus(userId);
    this.conversations['conversationLists'].filter(contact => {
       if(contact.user.id == userId){
        contact.unread_count = 0;
       }
    });
    // for socket service
    // if(userlist.roles_name=="Student"){
    //   this.join_room = "ch"+userId+this.myId;
    // }else {
    //   this.join_room = "ch"+this.myId+userId;
    // }
    // this.join();
  }

  public getreadcount(userId, readcount ){
    this.chat.readCountOfSelectedUser(userId, readcount).subscribe(response=>{
      this.responsereadcount = response['readcount'];
      this.currentId = userId;
    })
  }

  // This Method will select the Admin Conversation
  public getActiveConversations(name ,  tabName:string = ''){
    this.currentTab = tabName;
    this.chatLoader = false;
    this.showconversation = 'no';
    this.getActiveChat();
    $('#filterInput').val('');
  }

  // This Method is used to get the Archive Conversations
  public getArchiveConversations(tabName:string = ''){
    $('#filterInput').val('');
    this.currentTab = tabName;
    this.chatLoader = false;
    this.showconversation = 'no';
    this.chat.archiveConversation().subscribe(response => {
      this.archeiveChats = JSON.parse(atob(response['archive-conversations']));
      this.userLists = this.archeiveChats;
    })
  }

   // This Method is used to select the Image is drag and drop
    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
    }

   // This Method is used to drop the image selected
    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
        this.disableUpload();
    }


    public scrollToBottom(id){
      this.chat.getscrolldata(id).subscribe(response=>{
        this.heightofscroll = response['scheight'];
        if ( response['scheight'] != 0 ) {
          this.heightofscroll = $('#conversation-container').prop("scrollHeight")
          $("#conversation-container").animate({ scrollTop: this.heightofscroll}, 100);
        } else {
          this.heightofscroll = $('#conversation-container').prop("scrollHeight")
          $("#conversation-container").animate({ scrollTop: $('#conversation-container').prop("scrollHeight")}, 100)
        }

        window.scrollBy(0, this.heightofscroll);
      });
      //console.log(this.heightofscroll)
      this.chat.scrollmethod(this.heightofscroll, id).subscribe(response=>{
      });
    }

    // This Method is used to upload the Files in the chat
    public sendFile(){
      this.imageLoader$.next(true);
      this.chat.UploadFiles(this.formData).subscribe(response => {
      if(response['status'] == 'success'){
        let fileUploaded = JSON.parse(atob(response['fileData']));
        this.fileUploadMessages(fileUploaded.data);
        }
      });
    }
 
    // This Method is used to upload the Files
    public disableUpload(){
      if(this.files.length>0){
        $('#upload').prop("disabled", false);
      }else{
        $('#upload').prop("disabled", true);
      }
    }

    // This Method send the uploaded files as messages
    public fileUploadMessages(files){
       for(var file of files){
        this.filesSendMessage(file);
       }
    }

    public removeAllFiles(){
     this.files = [];
     this.formData.delete("file[]");
   }

   public filesSendMessage(file){
      let fileData = Object();
      Object.assign(fileData , {to_id:this.selectedUserID+""});
      Object.assign(fileData , {message:file.attachment});
      Object.assign(fileData , {message_type:'1'});
      Object.assign(fileData , {file_name: file.file_name});
      Object.assign(fileData , {is_my_contact:'1'});
      Object.assign(fileData , {from_id:this.selectedUserID});
      Object.assign(fileData,  {sendAsNotification: 'No'});
      this.chat.sendMessage(fileData).subscribe(response => {
       this.scrollToBottom(this.selectedUserID);
       if(response['status'] == 'success'){
       this.closeFileUploader.nativeElement.click();
       this.removeAllFiles();
       this.messages['conversations'].push(response['message']);
       this.imageLoader$.next(false);
       }
      })
      this.sentmsg = 'yes';
   }

  public editProfile( userName ){  
    if(userName == 'tutor-world-connect-admin-admin86147'){
      alert("You Have No Access");
    }else{
       this.router.navigate(['student/profile/'+userName]);
    }
  }



  public closeFileUploaderModal(){
      this.removeAllFiles();
    }

  public viewschedule( myid ){
    if(this.currentuser.isTutor()){
    this.router.navigate(['tutor/tutor-lessons/schedule/scht']);
    }else{
    this.lessonService.userFilterId(myid);
    this.router.navigate(['student/lessons/scheduled/sch']);
    }
  }


  /*
  Get Booking or Schedule Details From Backend
  */
  getSpecificViewDetails(bookingId , bookingDateId){
    this.chat.getSpecificViewDetails(bookingId , bookingDateId).subscribe(response =>{
          let data = JSON.parse(atob(response['viewDetails']));
         if(response['status'] == 'success'){
          this.viewDetails = data;
         }else if(response['status'] == 'failed'){
          this.toastr.error(response['message']);
         }
    });
  }


  /*
  Clear Modal Data
  */
  clearViewDetailsData(){
    this.viewDetails = null;
  }

  /*
  Redirect TO Lesson Board
  */
  viewSource(){
    if(this.currentuser.hasRole('Student')){
      this.router.navigate(['student/lessons/scheduled/details',{id:this.viewDetails.booked_id , dId :this.viewDetails.booking_date_id}]);
    }else if(this.currentuser.hasRole('Tutor')){
      this.router.navigate(['tutor/tutor-lessons/board',{id:this.viewDetails.booked_id , dId :this.viewDetails.booking_date_id}]);
    }
    this.closeViewDetailsModal.nativeElement.click();
  }

  // Socket Methods
  // join(){
  //   this._socketService.joinRoom({user:this.myId, room:this.join_room});
  // }

  // leave(){
  //   this._socketService.leaveRoom({user:this.myId, room:this.join_room});
  // }

  // sendSocketMessage()
  // {
  //   this.pusherService.channel.bind("normal-chat",data=>{
  //     console.log("Pusher is working");
  //   });
  // }  
}