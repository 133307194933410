import { Component, OnInit, ViewChild, ElementRef, Inject, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { ActivatedRoute, Router,  RouterModule } from '@angular/router';
import { VideocallService } from './videocall.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CurrentUser } from '../../auth/current-user';
import { ZoomMtg } from '@zoomus/websdk';
import { BoardComponent } from './../../student/lessons/board/board.component';
import { LessonsService } from '@student/lessons/lessons.service';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PusherService } from 'app/pusher.service';

declare var $;

@Component({
  selector: 'app-videocall',
  templateUrl: './videocall.component.html',
  styleUrls: ['./videocall.component.css']
})
export class VideocallComponent implements OnInit {
  public attachment;

  @ViewChild('closeActionModal') closeActionModal: ElementRef;
  @ViewChild('closebtn') closebtn: ElementRef;
  @ViewChild('closeFileUploader') closeFileUploader: ElementRef;

  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  signatureEndpoint:any;
  // apiKey = 'CYkH4GteRxKhyOw69Dl6Dw'
  meetingNumber = '77893543767'
  role = 1
  leaveUrl:any;
  userName = 'Angular'
  userEmail = ''
  passWord = ''
  signature = ''

  public comments = new Array();
  public comment: string;
  public error;
  public bookingId;
  public isInitializing = true;
  public meetingCreated = false;
  public booking;
  public twillio_url;
  public leaveComponent = false;
  public nextUrl;
  public IsmodelShow:boolean = false;
  public bookingID;
  public bookingDateID;
  public oppRole: string;
  public userId: any;
  public studentName: any;
  public tutorId: any;
  public studentId: any;
  public myRole: string;
  
  public imageLoader$ = new BehaviorSubject(false);
  public commentForm = this.fb.group({
    commentValue: ['',Validators.required]
    });
    get commentValue(){ return this.commentForm.get('commentValue');}

  public errorMessages = {
          review :[ { type:'required',message:'Review is required'}],
          commentValue :[{type:'required',message:'Comment is required'}],
       }

  files: File[] = [];
  formData = new FormData();


  constructor(public httpClient: HttpClient, @Inject(DOCUMENT) document, private route: ActivatedRoute,
    private videocallService: VideocallService,
    private toastr: ToastrService,
    private router: Router,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private lessonService: LessonsService,
    public currentUser: CurrentUser,
    public sanitizer: DomSanitizer,
    public pusherService:PusherService) {
    this.route.params.subscribe( params =>{
      this.bookingId = params['id'];
    });

    

    

  // ZoomMtg.setZoomJSLib('https://source.zoom.us/1.8.6/lib', '/av');

  // ZoomMtg.preLoadWasm();
  // ZoomMtg.prepareJssdk();
  }

  ngOnInit() {
        this.userId = this.currentUser.userId();
        this.studentName = this.currentUser.userName();
    if(this.currentUser.hasRole('Student')){
      this.oppRole = 'Teacher';
      this.myRole = 'Student';
    }else{
      this.oppRole = 'Student';
      this.myRole = 'Teacher';
    }

    this.lessonService.bookingData.subscribe(response=>{
      //console.log(response);
    });


    this.comments = [];
    let data = Object();
    Object.assign(data,{bookingId:this.bookingId});
    //console.log(this.bookingId);

    this.videocallService.tutorcallVideo(data).subscribe(response=>{

      //console.log(response.booking,'response.booking');
      var tutor_user_name = response.booking.tutor.username.replace(/\s/g, "");
      if(response.booking.group_chat_id){

        this.pusherService.createCommonChannel(tutor_user_name);
      }else{
          if(this.currentUser.hasRole('Student')){
                  this.pusherService.createChannel(this.currentUser.userId(),response.booking.tutor.id);
          }else{
                  this.pusherService.createChannel(response.booking.student.id , this.currentUser.userId());
          }
      }
      
      //console.log(response,'lllllllll');
       

      this.bind();

      this.tutorId = response.booking.tutor.id;
      this.studentId = response.booking.student.id
      //console.log(response.booking ,'response.booking')
     
      
        this.lessonService.onVideo(response);
        if (typeof response.error !== 'undefined') {
          this.error = response.error
          this.toastr.error(response.error);
          return;
        }

        this.meetingNumber = response.id;
        this.role = response.role;
        this.userName = response.user_name;
        this.userEmail = response.user_email;
        this.passWord = response.password;
        this.signatureEndpoint = response.signatureEndpoint;
        this.leaveUrl = response.leaveUrl;
        this.signature = response.signature;
        this.twillio_url = this.sanitizer.bypassSecurityTrustResourceUrl(response.twillio_url);

        this.meetingCreated = true;
        this.isInitializing = false;
        this.booking = response.booking;
        this.bookingID = response.booking.booking_id;

        this.bookingDateID = response.booking.booking_dates_id;
        // console.log(this.bookingID);
        // this.startMeeting(response.signature)
        // this.getSignature();
        //this.bind();
    });

    
    this.getComments();

   
    
   
  }


  public bind(){
     
    this.pusherService.channel.bind('tutor.message', res => {
      //console.log( res,'asdasdaaaaaaaaaaaaaa');
      //console.log(res[0].original.data,'asdasdaaaaaaaaaaaaaa');
      let data =  JSON.parse(atob(res[0].original.data));
      this
      this.comments = data['comments'];
      localStorage.setItem('Comments',JSON.stringify(this.comments));
     // console.log(this.comments);
      $("#postComment").val("");
    });  
  }

    public comentValue(value){
  this.comment = value;
  }

        public keyPressHandler(e) {
  //
  if ( (e.keyCode === 13) || (27 === e.which) || (13 === e.which) ) {
     this.postComment()
     e.preventDefault();
    }
  }

    public postComment(){
      if(this.comment){
      let val = this.comment;
      this.comment = "";
    let commentData = Object();
    Object.assign(commentData,{commentValue:    val});
    
    if(this.currentUser.hasRole('Student')){  
    Object.assign(commentData,{to_id: this.booking.tutor_id});
    }else{
      Object.assign(commentData,{to_id: this.booking.student_id});
    }
    Object.assign(commentData,{from_id:         this.currentUser.userId()});
    Object.assign(commentData,{booked_id:       this.booking.booking_id});
    Object.assign(commentData,{booking_dates_id:this.booking.booking_dates_id});
    Object.assign(commentData,{group_chat_id:this.booking.group_chat_id});
    this.lessonService.postStudentComment(commentData).subscribe(response=>{
      let data =  JSON.parse(atob(response['data']));
      this.comments = data['comments'];
      $("#postComment").val("");
      
    });

    }
    this.bind();
  }

  public getComments(){
    let commentData = Object();
    Object.assign(commentData , {'bookingId': this.bookingID});
    Object.assign(commentData , {'bookingDateID': this.bookingDateID});
    this.videocallService.getComments(commentData).subscribe(res => {                                        
      this.comments = res['data'];
    }, err => {
      console.log(err);
    });
  }

  getSignature() {
    this.startMeeting(this.signature);
  }
  public closeModal(){
    this.closeActionModal.nativeElement.click();
      if(this.currentUser.hasRole('Tutor')){
          window.open(this.nextUrl,"_blank");
        }
        else{
           window.open(this.nextUrl,"_blank");
        }
  }

  public exitModal(){
     this.closeActionModal.nativeElement.click();
     this.navigateUrl();
   }
   
   public navigateUrl(){
    this.leaveComponent = true;
    this.router.navigate([this.nextUrl]);
   }
  public  openChat(){
          $('.chat_fab .wrap').toggleClass("ani");
          $('.main_chat').toggleClass("open");
          $('.img-fab .img').toggleClass("close");
          this.getComments();
      }        


     // This Method is used to select the Image is drag and drop
    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
      }

       // This Method is used to drop the image selected
    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
        this.disableUpload();
    }
     public removeAllFiles(){
      this.files = [];
      this.commentForm.reset();
      this.formData.delete("file[]");
    }

  public closeFileUploaderModal(){
      this.removeAllFiles();
    }

      // This Method is used to upload the Files in the chat
    public sendFile(){
      this.commentForm.markAllAsTouched();
      if(this.commentForm.valid){
        this.formData.append("commentValue", this.commentForm.value.commentValue);
        this.formData.append("booked_id", this.booking.booking_id);
        this.formData.append("booking_dates_id",this.booking.booking_dates_id );
        if(this.currentUser.hasRole('Student')){  
           this.formData.append("to_id" , this.booking.tutor_id);
            }else{
           this.formData.append("to_id" , this.booking.student_id);
           }
        this.imageLoader$.next(true);
        this.lessonService.UploadFiles(this.formData).subscribe(response => {
        if(response['status'] == 'success'){
        this.closeFileUploader.nativeElement.click();
        let data =  JSON.parse(atob(response['data']));
        this.comments = data['comments'];
        this.imageLoader$.next(false);
             }
          })
        }
      }

        // This Method is used to upload the Files
    public disableUpload(){
      if(this.files.length>0){
        $('#upload').prop("disabled", false);
      }else{
        $('#upload').prop("disabled", true);
      }
    }

    // This Method send the uploaded files as messages
    public fileUploadMessages(files){
       for(var file of files){
        this.filesSendMessage(file);
       }
    }

     public filesSendMessage(file){


    }

    public viewAttachmentImage(image){
      this.attachment = image;
    }


  startMeeting(signature) {

  }



}
