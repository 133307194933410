import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { VideocallComponent } from '@athand/videocall/videocall.component';


@Injectable({
  providedIn: 'root'
})
export class AlertGuard implements CanDeactivate<VideocallComponent> {
  canDeactivate(
    component: VideocallComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(component.leaveComponent == false){
      let url: string = nextState.url;
      component.nextUrl = url;
      document.getElementById("openModalButton").click();
    }
    else{
      return true;
    }

  }
  
}
