import { Injectable } from '@angular/core';
import { AppHttpClientService } from '../../core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class VideocallService {

	constructor(private http: AppHttpClientService){}

	public tutorcallVideo(payload: Object){
         return this.http.post('tutorcallVideo', payload);
    }

    public getComments(payload: Object){
      return this.http.get('getComments', payload);
    }

}