import { Injectable, ViewChild } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable } from 'rxjs';
import { Echo } from 'laravel-echo-ionic';
import { CurrentUser } from '../../auth/current-user';
import { BehaviorSubject } from 'rxjs';
import { Bootstrapper } from '../../core/bootstrapper.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  echo: any = null;
  public newMessage = new BehaviorSubject(Array);
  sharedMessage = this.newMessage.asObservable();

  public host_socket;

  constructor(public currentuser: CurrentUser, private bootstrapper: Bootstrapper) {

       // this.host_socket = this.bootstrapper.data.host_socket;

       // this.echo = new Echo({
       //      broadcaster: 'socket.io',
       //      host: this.host_socket,
       //  });

       //  this.echo.connector.socket.on('connect', function () {
       //      console.log('CONNECTED');
       //  });

       //  this.echo.connector.socket.on('reconnecting', function () {
       //      console.log('CONNECTING');
       //  });

       //  this.echo.connector.socket.on('disconnect', function () {
       //      console.log('DISCONNECTED');
       //  });

        // this.echo.channel('laravel_database_private-user.'+this.currentuser.userId())
        //     .listen('UserEvent', (data) => {
        //     if(data['to_type'] == "App\\ChatModels\\Conversation"){
        //         this.newMessage.next(data);
        //     }
        //     });

        // this.echo.channel('laravel_database_home')
        //     .listen('NewMessage', (data) => {
        //         console.log(data);
        //     });

           // this.echo.channel('laravel_database_chat')
           //  .listen('ChatEvent', (data) => {
           //      console.log(data);
           //  });
  }
}
