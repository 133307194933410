import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AthandModule } from '../athand.module';
import { TranslationsModule } from '@core/translations/translations.module';

@NgModule({
  declarations: [ChatComponent],
  imports: [
    CommonModule,
    NgxDropzoneModule,
    AthandModule,
    TranslationsModule,
  ],exports:[
  ChatComponent
  ]
})
export class ChatModule { }
