<div class='col-sm-9 rightCol'>
   <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal" style="display:none;"></button>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Video Call">Video Call</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <div class="row formspace">
         <div class="col-sm-12 custom_hgt">
            <span *ngIf="error">{{error}}</span>
            <iframe [src]="twillio_url" style="
               width: 100%;
               height: 95%;
               border: 0px;
               " allow="camera *;microphone *;display-capture *"></iframe>
         </div>
      </div>
   </div>
   <div class="Chat_btn">
      <div class="chat_wrap">
         <div class="main_chat popup-ani">
            <div class="col-sm-12">
               <div class="row m-0">
                  <h5 class="text-left color2">
                     <p><b trans="Lesson Wall">
                        Lesson Wall
                        </b>
                     </p>
                     <p class="palegreen" trans="leave a comment or upload a document."> leave a comment or upload a document.</p>
                  </h5>
                  <div class="greyColor footer-schedule_Chat chat_schedule_area-text">
                     <div class="col-md-1">
                        <button id="staticBackdrop" data-backdrop="static" class="chat__area-media-btn btn" data-toggle="modal" data-target="#viewModalpaperclip">
                        <i class="fa fa-paperclip" aria-hidden="true"></i>
                        </button>
                     </div>

                     
                     <div class="col-md-11 chat_pos" style="padding:0px;">
                        <form method="post" class="search_chat enterCol TWC-control">
                           <input id="postComment" (keydown)="keyPressHandler($event)" (input)="comentValue($event.target.value)" type="text" class="form-control" placeholder="Type Message..." trans-placeholder="Type Message...">
                           <i (click) = "postComment()" class="fa fa-paper-plane-o chat__area-send-btn" aria-hidden="true"></i>
                        </form>
                     </div>
                  </div>
               </div>
               <div class="col-sm-12">
                  <!-- main chat -->
                  <div class="T_S-chat" id="T_S-chat">
                     <div *ngFor="let comment of comments">
                        <h5 *ngIf="userId != comment.from_id" class="text-left color2">
                           <p><b>
                              {{oppRole}}: {{comment.from}}
                              </b><span class="Text-Right"> {{comment.created_at | date: 'yyyy-MM-dd'}}</span>
                           </p>
                           <div class="footer-schedule_Chat chat_chat_bdr">
                              <p class="palegreen"> {{comment.comment}}</p>
                              <br/>
                              <p *ngIf="!comment.attachments"><a class="nocolor" trans="No Attachment">No Attachment</a></p>
                              <p *ngIf="comment.attachments">
                                 <a *ngFor= "let attachment of comment.attachments; let i = index" class="nocolor" >
                                 <span data-target="#viewAttachment2" data-toggle="modal" (click)="viewAttachmentImage(attachment)" >Attachment {{i+1}} </span>
                                 </a>
                              </p>
                           </div>
                        </h5>
                        <h5 *ngIf="userId == comment.from_id" class="text-left color2">
                           <p><b>
                              {{myRole}}: {{studentName}}
                              </b><span class="Text-Right"> {{comment.created_at | date: 'yyyy-MM-dd'}}</span>
                           </p>
                           <div class="footer-schedule_Chat chat_chat_bdr">
                              <p class="palegreen">{{comment.comment}}</p>
                              <br/>
                              <p *ngIf="!comment.attachments"><a class="nocolor" trans="No Attachment">No Attachment</a></p>
                              <p *ngIf="comment.attachments">
                                 <a *ngFor= "let attachment of comment.attachments; let i = index" class="nocolor" >
                                 <span data-target="#viewAttachment2" data-toggle="modal" (click)="viewAttachmentImage(attachment)" >Attachment {{i+1}} </span>
                                 </a>
                              </p>
                           </div>
                        </h5>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="chat_fab" (click)="openChat()">
            <div class="wrap">
               <div class="img-fab img"></div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="myModal" role="dialog">
   <div class="modal-dialog m-0">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header bdb-none">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
         </div>
         <div class="modal-body" style="text-align:center;">
            <h4 class="modal-title popup_header" trans="Do you want to">Do you want to : </h4>
            <br>
            <div class="web_alert">
               <p class="desc"> By navigating to other pages you will exit this page. Are you sure you want to do this ? </p>
               <br>
               <!-- <p class=""> -->
                  <div class="TT-formGroup pb-10">
                     <a  class="TT-primaryBTN" (click)="closeModal()">Open New Tab</a> 
                     <a class="TT-primaryBTN" (click)="exitModal()" style="margin: 0 15px;">Exit Page</a>
                  </div>
                
               <!-- </p> -->
               <button type="button" class="btn btn-default" #closeActionModal data-dismiss="modal" style="display:none;"></button>
            </div>
            <div class="mobile_alert">
               <p class="desc"> By navigating to other pages you will exit this page. Are you sure you want to do this ? </p>
               <br>
               <button type="button" class="btn btn-primary mr-2 mb_btn" data-dismiss="modal">Stay On Page</button>
               <a  (click)="exitModal()" class="btn btn-danger pb-10 mb_btn">Exit Page</a>
            </div>
         </div>
      </div>
   </div>
</div>
<div  class="modal fade" id="viewAttachment2" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog mt-100">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" #closeRatingModal aria-hidden="true">
            ×
            </button>
         </div>
         <div class="modal-body M_wrapper">
            <img src="{{attachment}}" width="400" class="img-fluid">
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="viewModalpaperclip" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog mt-100">
      <div class="modal-content content-new">
         <div class="modal-header">
            <button #closeFileUploader (click)="closeFileUploaderModal()" type="button" class="close" data-dismiss="modal" aria-hidden="true">×
            </button>
            <h4 class="modal-title md-title" id="myModalLabel" trans="File Upload">
               File Upload
            </h4>
            <br/>
            <form [formGroup]="commentForm">
               <div  class="form-group">
                  <label for="comment" trans="Comment">
                  Comment
                  </label>
                  <input type="comment" name="comment" class="form-control">
                  <div *ngFor= "let error of errorMessages.commentValue">
                     <ng-container *ngIf="commentValue.hasError(error.type) && (commentValue.touched)">
                        <small class="error-message">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
            </form>
            <br/>
            <ngx-dropzone accept="image/jpeg,image/jpg,image/png,image/gif" (change)="onSelect($event)">
               <ngx-dropzone-label trans="Select a file or drag here">Select a file or drag here</ngx-dropzone-label>
               <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
               </ngx-dropzone-image-preview>
            </ngx-dropzone>
            <br/>
            <div class="text-right">
               <button (click)="sendFile()" class="btn btn-primary text-right" id="upload" trans="Send">
                  Send
                  <app-loader *ngIf="imageLoader$ | async"></app-loader>
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
