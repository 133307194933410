import {Injectable} from '@angular/core';
import {AppHttpClientService} from '../../core/http/app-http-client.service';
import {Observable} from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    constructor(private http: AppHttpClientService) {}
    

    public sourceSelectedUserChat = new BehaviorSubject<Object>(null);
    sharedSelectedUserChat = this.sourceSelectedUserChat.asObservable();

    //Bring Required Data 
    public chatInfo(){
    	return this.http.get('chatInfo');
    }

    public seletedUserConversation(id){
    	return this.http.get('users/'+id+'/conversations');
    }

    public readCountOfSelectedUser(id, unreadcount){
        return this.http.put('users/'+id+'/conversations_unreadcount/' +unreadcount);
    }

    public adminSideReadCountOfSelectedUser(id, unreadcount){
        return this.http.put('users/'+id+'/admin_side_conversations_unreadcount/' +unreadcount);
    }

    public archievestatuses(id){
        return this.http.get('users/'+id+'/archievestatuses');
    }

    //get Specific Lesson Or Booking Details 
    public getSpecificViewDetails(bookingId , bookingDateId){
      let parmsUrl = ((bookingId) ? '/' + bookingId : '/0') + ((bookingDateId) ? '/' + bookingDateId : '/0');
      return this.http.get('lessonOrBookingDetails' + parmsUrl)
    }

    // This Method send the message to selectedUser
	public sendMessage(payload: object){
		return this.http.post('send-message', payload);
	}

    public scrollmethod(height, id){
       return this.http.get('users/'+height+'/scrollmethodurl/' +id);
   }

   public getscrolldata(id){
       return this.http.get('users/'+id+'/getscrollheight');
   }
   
    // This Method is requesting the backend to archive selected users
    public archiveUser(id){
        return this.http.get('conversations/'+id+'/archive-chat');
    }

    // This Method is requesting the backend to unarchive the selected user
    public unArchiveUser(id){
        return this.http.get('conversations/'+id+'/unArchive-chat');
    }

    // This Method is used to get the Archive Conversation Lists
    public archiveConversation(){
        return this.http.get('archive-conversations');
    }

    // This Mthod is used to reques the backend to upload the files
    public UploadFiles(files){
        return this.http.post('file-upload',files);
    }

    public viewProfile(payload){
        return this.http.post('viewProfile' , payload);
    }

    public AdminTeachersConversation(){
        return this.http.get('admin-teachers-conversations');
    }

    public AdminStudentsConversation(){
        return this.http.get('admin-students-conversations');
    }

    public selectedUserChat(user){
      this.sourceSelectedUserChat.next(user);
    }


}